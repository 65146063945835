html {
  font-size: 16px;
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

body {
  min-height: 100vh;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 12px;
}

@media print {
  html,
  body {
    /* font-size: 9px; */
  }
  body {
    border-bottom: 1px solid transparent;
  }
  body > div > div {
    padding-top: 0 !important;
  }
  .TableOfContents {
    display: none !important;
  }
  .appBar {
    display: none !important;
  }
  .Quote-paper {
    box-shadow: none !important;
  }
}

@font-face {
  font-family: 'CustomFont-SW';
  src: url('https://res.cloudinary.com/taptapquote/raw/upload/v1709849288/paintscout/partners/sw/fonts/FontDropcloth-RegularItalic.ttf')
    format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'CustomFont-SW';
  src: url('https://res.cloudinary.com/taptapquote/raw/upload/v1709849287/paintscout/partners/sw/fonts/FontDropcloth-Regular.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CustomFont-SW';
  src: url('https://res.cloudinary.com/taptapquote/raw/upload/v1709849287/paintscout/partners/sw/fonts/FontDropcloth-MediumItalic.ttf')
    format('truetype');
  font-weight: 500; /* Medium */
  font-style: italic;
}

@font-face {
  font-family: 'CustomFont-SW';
  src: url('https://res.cloudinary.com/taptapquote/raw/upload/v1709849286/paintscout/partners/sw/fonts/FontDropclothDisplay-Regular.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CustomFont-SW';
  src: url('https://res.cloudinary.com/taptapquote/raw/upload/v1709849285/paintscout/partners/sw/fonts/FontDropcloth-Medium.ttf')
    format('truetype');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'CustomFont-SW';
  src: url('https://res.cloudinary.com/taptapquote/raw/upload/v1709849284/paintscout/partners/sw/fonts/FontDropclothDisplay-Light.ttf')
    format('truetype');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'CustomFont-SW';
  src: url('https://res.cloudinary.com/taptapquote/raw/upload/v1709849283/paintscout/partners/sw/fonts/FontDropclothDisplay-Bold.ttf')
    format('truetype');
  font-weight: bold; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'CustomFont-SW';
  src: url('https://res.cloudinary.com/taptapquote/raw/upload/v1709849283/paintscout/partners/sw/fonts/FontDropcloth-Bold.ttf')
    format('truetype');
  font-weight: bold; /* Bold */
  font-style: normal;
}
